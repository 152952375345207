<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name-en"
                v-model="CoursesForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name-ar"
                v-model="CoursesForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-input
                id="description-en"
                v-model="CoursesForm.description_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Description"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description (AR)"
              label-for="description"
            >
              <b-form-input
                id="description-ar"
                v-model="CoursesForm.description_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Description"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Issuer"
            rules="required"
          >
            <b-form-group
              label="Issuer"
              label-for="issuer"
            >
              <b-form-input
                id="issuer"
                v-model="CoursesForm.issuer"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Issuer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Expired At"
            rules="required"
          >
            <b-form-group
              label="Expired At"
              label-for="expired at"
            >
              <b-form-input
                id="expired_at"
                v-model="CoursesForm.expired_at"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Expired At"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- <validation-provider
            #default="validationContext"
            name="DOC"
            rules="required"
          > -->
          <b-form-group
            label="DOC"
            label-for="file-doc"
          >
            <b-form-file
              id="file-doc"
              v-model="CoursesForm.doc"
              @change="handleFileUpload($event, 'doc')"
            />
            <!-- <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
          </b-form-group>
          <!-- </validation-provider> -->
        </b-col>
        <b-col
          v-if="showDownLoad"
          md="6"
          class="d-flex align-items-center py-2"
        >
          <a
            :href="CoursesForm.doc.url"
            target="_blank"
          >

            <feather-icon
              icon="DownloadCloudIcon"
              class="cursor-pointer"
            />
            Download file
          </a>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCourses()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCourses()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      showDownLoad: false,
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const CoursesForm = ref({
      doc: [],
    })
    return {
      getValidationState,
      CoursesForm,
    }
  },
  mounted() {
    this.showCourses()
  },
  methods: {
    handleFileUpload(event, fieldName) {
      const { files } = event.target
      this.CoursesForm[fieldName] = [...this.CoursesForm[fieldName], ...Array.from(files)]
    },
    showCourses() {
      if (this.$route.params.id) {
        axios.get(`user/${this.$route.params.C_id}/certificate/${this.$route.params.id}`).then(res => {
          this.CoursesForm = decryptData(res.data.payload).data.certificate
          if (decryptData(res.data.payload).data.certificate.doc) {
            this.showDownLoad = true
          }
          this.CoursesForm.doc = []
        })
      } else {
        return false
      }
      return true
    },

    addCourses() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.CoursesForm) {
          formData.append(key, this.CoursesForm[key])
        }

        axios.post(`user/${this.$route.params.C_id}/certificate/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'courses', params: { C_id: this.$route.params.C_id } })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.CoursesForm) {
          formData.append(key, this.CoursesForm[key])
        }

        axios.post(`user/${this.$route.params.C_id}/certificate`, formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'courses', params: { C_id: this.$route.params.C_id } })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
